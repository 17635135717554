import { PLAN_COUNT } from "~/constants/plan";
import type { RecommendItem } from "~/types";

export default function (
  sekou: RecommendItem[],
  items: RecommendItem[],
  review: RecommendItem,
) {
  // 並び順 プラン->リフォーム->新築->人気施工例->Google口コミ->mobi->レビュー
  const recommend: RecommendItem[] = [
    {
      type: "sekou",
      param: {
        count: PLAN_COUNT,
        text_list: ["プラン事例"],
      },
      alt: "外構・リフォーム工事のおすすめプラン事例",
      path: "/images/recommend/plan.png",
      link: "/plan",
      preload: true,
      target: "",
    },
    ...sekou,
    {
      type: "text",
      param: {
        text_list: ["先月の", "人気施工例", "ランキング"],
      },
      alt: "人気の外構・リフォーム・エクステリア工事の施工例ランキング",
      path: "/images/recommend/ranking.png",
      link: "/contents.php/ranking",
      preload: false,
      target: "",
    },
    {
      type: "google_review",
      param: {
        count: 4.5,
        count2: 2217,
      },
      alt: "外構・リフォーム工事の口コミレビュー",
      path: "/images/recommend/google.png",
      link: "https://www.google.com/maps/d/embed?mid=1eE77JAJSmU0jw-uLJ0V0L9KxU5hoWiE&ehbc=2E312F",
      preload: false,
      target: "_blank",
    },
    ...items,
    review,
  ];

  return { recommend: recommend };
}
